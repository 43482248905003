<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'商品'"
    >
      <div class="search" slot="tool">
        <el-input
          placeholder="根据姓名查询"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">姓名</template>
        </el-input>
        <el-input
          placeholder="根据身份查询"
          v-model="searchQuery.personIdentityId"
          class="searchInput"
        >
          <template slot="prepend">身份</template>
        </el-input>
        <el-input
          placeholder="根据电话查询"
          v-model="searchQuery.phone"
          class="searchInput"
        >
          <template slot="prepend">电话</template>
        </el-input>
        <el-input
          placeholder="根据卡号查询"
          v-model="searchQuery.cardNumber"
          class="searchInput"
        >
          <template slot="prepend">卡号</template>
        </el-input>
        <el-input
          placeholder="根据人员编号查询"
          v-model="searchQuery.code"
          class="searchInput"
        >
          <template slot="prepend">编号</template>
        </el-input>
        <el-input
          placeholder="根据部门查询"
          v-model="searchQuery.deptIdsString"
          class="searchInput"
        >
          <template slot="prepend">部门</template>
        </el-input>


        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">状态</div>
          <el-select
            filterable
            v-model="searchQuery.status"
            clearable
            placeholder="根据状态筛选"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/dh/personnelMgt",
      searchVal: "",
      jobList: [],
      departmentList: [],
      searchQuery: { name: "", typeId: "" },
      props: [
        {
          label: "姓名",
          prop: "name",
          align: "center",
        },

        {
          label: "性别",
          prop: "sex",
          align: "center",
        },
        {
          label: "人员编号",
          prop: "code",
          align: "center",
        },
        {
          label: "部门",
          prop: "deptName",
          align: "center",
        },
        {
          label: "身份",
          prop: "personIdentity",
          align: "center",
        },
        {
          label: "电话",
          prop: "phone",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "商品名称",
          prop: "name",
          type: "input",
        },
        {
          label: "单位",
          prop: "unitId",
          type: "select",
          selectData: [],
        },

        {
          label: "类别",
          prop: "typeId",
          type: "cascader",
          selectData: [],
        },
        {
          label: "图片",
          prop: "imagePath",
          type: "upload",
        },
      ],
    };
  },
  created() {
    // this.getSelectList();
    this.getDepartmentList()
  },
  methods: {
    // 获取部门列表
    async getDepartmentList(){
      let res = await this.remote.commonGet({apiName:'/department'})
      console.log('res',res);
      
    },
    getUnitList() {
      api.dictionary.getAllList({ type: "商品单位" }).then((res) => {
        this.formProps[1].selectData = res.data;
      });
    },
    async getSelectList() {
      let treeArr = [];
      await api.businessType.getTreeList().then((res) => {
        let arr = res.data;

        arr.forEach((item) => {
          this.typeList.push({ value: item.id, label: item.name });
          if (item.child) {
            let children = [];
            item.child.forEach((items) => {
              children.push({
                value: items.id,
                label: items.name,
              });
            });
            treeArr.push({
              value: item.id,
              label: item.name,
              children: children,
            });
          } else {
            treeArr.push({
              value: item.id,
              label: item.name,
            });
          }

          this.formProps[2].selectData = treeArr;
        });
        console.log("tree", this.typeList);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
